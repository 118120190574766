.StrengthInputHelper .StrengthIndicator {
  display: inline-block;
}

.StrengthInputHelper_help {
  background: #eee;
  padding: 4px;
  font-size: 12px;
  font-style: italic;
}

.StrengthInputHelper_input {
  padding: 4px;
}

.StrengthInputHelper_prompt {
  margin-right: .5em;
  font-weight: bold;
}
