.SelectInputHelper_help {
  background: #eee;
  padding: 4px;
  font-size: 12px;
  font-style: italic;
}

.SelectInputHelper_input {
  padding: 8px;
}

.SelectInputHelper_input > *:not(:last-child) {
  margin-right: 8px;
}
