.InputObject {
  position: relative;
}

.InputObject > :not(.InputObject_input) {
  position: absolute;
  background: #fff;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 2px 2px 3px;
  z-index: 99;
  top: 100%;
  left: 0;
  right: 0;
}

.InputObject input::placeholder {
  color: #aaa;
  opacity: 1; /* Firefox */
}
