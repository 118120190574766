.Indicators:not(:empty) {
  display: flex;
  flex-wrap: wrap;
  overflow-x: hidden;
  height: 32px;
}

.Indicators > *:not(:last-child) {
  margin-right: 1em;
}
