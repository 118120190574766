.CalendarToolbar {
  text-align: center;
}

.CalendarToolbar h2 {
  display: inline-block;
  margin: 0 20px;
}

.CalendarToolbar i {
  cursor: pointer;
}