.Estimate {
  /**
   * Required for scrolling contents. See
   * https://medium.com/@stephenbunch/how-to-make-a-scrollable-container-with-dynamic-height-using-flexbox-5914a26ae336
   */
  min-height: 0;
  display: flex;
  flex-direction: column;
}

/* HACK: Remove extra space between steps & layout [twl 14.Nov.18] */
.Estimate > *:nth-child(2) {
  padding-top: 0;
  overflow-y: auto;
}
