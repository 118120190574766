/* NOTE: Due to the order the styles are injected into the page, we need the `div.` on `rbc-event`
         to create extra specificity to override the default Big Calendar styles. If we move to
         external stylesheets, or JSS, we can re-evaluate this. [twl 5.Oct.18] */
div.rbc-event {
  background: inherit;
  color: #333;
  font-size: 12px;
  font-family: Roboto,sans-serif;
  margin: 0 1px;
  padding: 2px 3px;
  display: flex;
}

div.rbc-event.rbc-selected {
  background: inherit;
  outline: dashed 1px #ccc;
}

div.rbc-event .rbc-event-content {
  display: flex;
}

.rbc-show-more {
  margin-left: 4px;
  color: #9cc04f;
}

.rbc-show-more:hover {
  color: #9cc04f;
  text-decoration: underline;
}

.rbc-event-marker {
  flex: 0 0 auto;
  align-self: center;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 3px;
  background: #ccc;
}

.rbc-event-title {
  font-weight: 500;
  margin-left: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
