body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-variant: tabular-nums;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 1.5;
  background: #fff;
}

/*
  TODO: Get rid of style overrides. Many of these come from the master AntD stylesheet we used
        before moving to Material UI. Figure out a way to correct the styles at the component level
        or theme level, not the global level. [twl 30.Sep.20]
 */
a {
  text-decoration: none;
  cursor: pointer;                   /* Ensure pointer even if link has no `href` attribute */
}

a[disabled] {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  pointer-events: none;
}

input, button, select, optgroup, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  line-height: 1.5 !important;
}

.Sidebar {
  padding: 0 20px;
}

.Widget {
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 7px 14px 0 rgba(59,65,94,.1), 0 3px 6px 0 rgba(0,0,0,.07);
  box-shadow: 0 0px 16px 0 rgba(59,65,94,.15);
}

@media only screen and (max-width:800px) {

  .App-Header,
  .Print-Layout,
  .Schedule-Layout,
  .Itemize-Layout,
  .Home-Layout {
    flex-direction: column;
  }

  .Home-Layout > div:last-child {
    order: 2;
  }

  .App-brand {
    display: none;
  }

  .App-title {
    text-align: left;
  }

  .App main h1 {
    font-size: 26px;
  }

  .Sidebar {
    margin: 40px 0;
  }

  .Placeholder.Box img {
    width: 100%;
  }

  .Login {
    width: 300px;
  }
}
