.TodoEditor .InputObject {
  display: flex;
  flex-direction: column;
  background: #fff;
  border: solid #ccc 1px;
  border-radius: 5px;
  padding: 4px;
  cursor: text;
}

.TodoEditor input {
  flex: 1 0 auto;
  padding: 2px 4px;
  border: 0;
}

.TodoEditor input:focus {
  outline: 0;
}
