.rbc-day-bg:hover {
  background: #eee;
}

.rbc-current {
  background: #666;
  color: #fff;
}

.rbc-today {
  background: none;
}

.rbc-date-cell {
  cursor: pointer;
}

.rbc-date-cell:not(.rbc-current):hover {
  background: #ccc;
}

.rbc-date-cell a,
.rbc-date-cell a:hover,
.rbc-date-cell a:active {
  color: inherit;
  text-decoration: none;
}

div.rbc-event.rbc-selected {
  outline: none;
}

div.rbc-event:hover {
  background: #eee;
}

.rbc-row *:focus {
  outline: none;
}
